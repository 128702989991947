import React from "react";
import SaliBg from "../../assets/Saly_bg.png";
import WaveWithLetters from "../../assets/Wavebluewithletters.png";
import WaveWithLettersSmall from "../../assets/waveblueLettersSmall.png";
import SuccessImage from "../../assets/success.png";
import { PricingPlanItems } from "./components";
import usePricingPlan from "../../hooks/usePricingPlan";
import "./PricingPlan.scss";
import { LogOutModal, Success, ThankYouSignUp } from "../../modals";
import InterrogativeImage from "../../assets/interrogativeImage.png";

const PricingPlan = () => {
  const {
    pricingPlans,
    activeId,
    clickedElementRef,
    isMobile,
    popupVisiblity,
    width,
    phone,
    setPhone,
    isSaving,
    error,
    sendToParentSuccess,
    sendToParentSuccessVisibility,
    isConfirmPopupVisible,
    isContactPopupVisible,
    isFailedPopupVisible,
    parentPopupVisibility,
    setParentPopupVisibility,
    setPopupVisibility,
    handleChoosePlan,
    handleTextToParent,
    setConfirmPopupVisibility,
    handleChoosePlanConfirm,
    handlePaymentRedirect,
    handleParentPaymentRedirect,
    setContactPopupVisibility,
    handleClick,
  } = usePricingPlan();
  return (
    <>
      <div className="pricing-plan">
        <div className="pricing-plan__content">
          <p className="pricing-plan__content__text">Pricing</p>
          <img
            src={SaliBg}
            alt="SaliBg"
            className="pricing-plan__content__image"
          />
        </div>
        <img
          src={
            isMobile && width <= 600 ? WaveWithLettersSmall : WaveWithLetters
          }
          alt="WaveWithLetters"
          className="pricing-plan__waveimg"
        />
      </div>
      {activeId ? (
        <>
          <PricingPlanItems
            handleChoosePlan={handleChoosePlan}
            handleClick={handleClick}
            handleTextToParent={handleTextToParent}
            activeId={activeId}
            foodItems={pricingPlans}
            isMobile={isMobile}
            refProp={clickedElementRef}
          />

          <LogOutModal
            image={InterrogativeImage}
            isOpened={parentPopupVisibility}
            close={() => setParentPopupVisibility(false)}
            leftBtnText="Close"
            rightBtnText="Send"
            isSaving={isSaving}
            error={error}
            setPhone={setPhone}
            handleLogOut={() => {
              handleParentPaymentRedirect(phone);
            }}
            text={`Text to Parent`}
            label={`Enter Parent's Phone`}
          />

          <Success
            image={SuccessImage}
            title="Success !"
            text={`You've chosen the ${
              pricingPlans.filter((plan) => plan.id === activeId)[0]?.title
            }. Enjoy the enhanced features and benefits that come with your upgraded account. Thank you for choosing us!`}
            isOpened={popupVisiblity}
            close={() => setPopupVisibility(false)}
          />
          <LogOutModal
            image={InterrogativeImage}
            text="Please confirm"
            leftBtnText="No"
            rightBtnText="Yes"
            close={() => setConfirmPopupVisibility(false)}
            isOpened={isConfirmPopupVisible}
            handleLogOut={() => {
              handlePaymentRedirect();
            }}
          />
          <ThankYouSignUp
            isOpened={isFailedPopupVisible}
            onClose={handleChoosePlanConfirm}
            image={SuccessImage}
            title={"Transaction Failed."}
            EmailCheckText={
              "If the issue persists, contact support. Apologies for the inconvenience."
            }
          />
          <ThankYouSignUp
            isOpened={isContactPopupVisible}
            onClose={handleChoosePlanConfirm}
            image={SuccessImage}
            title={"Success !"}
            EmailCheckText={
              "Thank you for selecting your plan! A representative from Career Scoops will take it from here."
            }
          />
          <ThankYouSignUp
            isOpened={sendToParentSuccess}
            onClose={() => sendToParentSuccessVisibility(false)}
            image={SuccessImage}
            title={"Success !"}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default PricingPlan;
