import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initSignUp, signupSuccessReset } from "../redux/actions/authActions";

const useStudentSignUp = () => {
  const [page, setPage] = useState(1);
  const signupSuccess = useSelector((state) => state.authReducer.signupSuccess);
  const isSaving = useSelector((state) => state.authReducer.isSaving);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState(15);
  const [error, setError] = useState("Please input email address");
  const [firstError, setFirstError] = useState("");
  const [lastError, setLastError] = useState("");
  const [showError, setShowError] = useState(false);
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [thinkingCareer, setThinkingCareer] = useState("");
  const [isSuccessVisible, changeSuccessVisibility] = useState(false);
  const [shareDataCheck, setShareDataCheck] = useState(false);
  const [grade, setGrade] = useState("");
  const [termsCheck, setTermsCheck] = useState(false);
  const [flavor, setFlavor] = useState("");
  const [careers, setCareers] = useState("");
  const [isErroModalOpened, setIsErrorModalOPened] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const errorFromBackend = useSelector((state) => state?.authReducer?.error);
  const [passwordError, setPasswordError] = useState("");
  const [careerCertainty, setCareerCertainty] = useState();
  const [affiliateCode, setAffiliateCode] = useState("");
  const getErrorFromBackend = () => {
    let result = [];
    if (typeof errorFromBackend === "object" && errorFromBackend !== null) {
      for (let key in errorFromBackend) {
        result.push(
          <p
            key={Math.random()}
            style={{
              textAlign: "left",
            }}
          >{`${key}: ${errorFromBackend[key][0]}`}</p>
        );
      }
    }
    return result;
  };
  const dispatch = useDispatch();
  const closeErrorModal = () => {
    setIsErrorModalOPened(false);
    dispatch({
      type: "SIGNUP_FAIL",
      payload: {
        error: null,
      },
    });
  };
  useEffect(() => {
    dispatch(signupSuccessReset());
  }, []);
  useEffect(() => {
    if (errorFromBackend) {
      setIsErrorModalOPened(true);
    }
  }, [errorFromBackend]);
  useEffect(() => {
    changeSuccessVisibility(signupSuccess);
  }, [signupSuccess]);

  useEffect(() => {
    // debugger;
    if (!firstName) {
      setFirstError("Please enter valid first name");
    }

    if (!lastName) {
      setLastError("Please enter valid last name");
    }
  }, [showError]);

  const checkEmail = (e) => {
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e)) {
      setError("Please enter a valid email address.");
    } else {
      setError("");
    }
    setShowError(false);
    setEmail(e);
    return;
  };

  const checkFirstName = (e) => {
    if (!firstName) {
      setFirstError("Please enter a valid first name");
    } else {
      setFirstError("");
    }
    setShowError(false);
    setFirstName(e);

    return;
  };

  const checkLastName = (e) => {
    if (!lastName) {
      setLastError("Please enter a valid last name");
    } else {
      setLastError("");
    }
    setShowError(false);
    setLastName(e);

    return;
  };

  const handleSignUp = () => {
    if (!password) {
      setPasswordError("Please enter password");
      return;
    }
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    if (!grade) {
      setCareerCertainty("Please choose certainty level");
      return;
    }

    dispatch(
      initSignUp({
        email,
        role: "Student",
        first_name: firstName,
        last_name: lastName,
        password,
        age,
        phone,
        address,
        zipCode,
        certainty: grade,
        affiliate_code: affiliateCode,
        careers,
        is_read_terms_and_policies: termsCheck,
        allow_data_sharing: shareDataCheck,
        favorite_ice_cream_flavor: flavor,
      })
    );
  };

  return {
    isSuccessVisible,
    changeSuccessVisibility,
    page,
    setPage,
    firstName,
    // setFirstName,
    setFirstName: checkFirstName,
    lastName,
    // setLastName,
    setLastName: checkLastName,
    email,
    isSaving,
    setEmail: checkEmail,
    phone,
    setPhone,
    age,
    error,
    firstError,
    lastError,
    showError,
    setShowError,
    setAge,
    password,
    setPassword,
    setAddress,
    setZipCode,
    thinkingCareer,
    setThinkingCareer,
    handleSignUp,
    flavor,
    setFlavor,
    careers,
    setCareers,
    grade,
    setGrade,
    termsCheck,
    setTermsCheck,
    shareDataCheck,
    setShareDataCheck,
    getErrorFromBackend,
    closeErrorModal,
    isErroModalOpened,
    confirmPassword,
    setConfirmPassword,
    passwordError,
    setPasswordError,
    careerCertainty,
    setCareerCertainty,
    affiliateCode,
    setAffiliateCode,
  };
};

export default useStudentSignUp;
