// contexts/FormContext.jsx
import React, { createContext, useContext, useState, useEffect } from "react";

const FormContext = createContext();

export const useFormContext = () => useContext(FormContext);

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zip_code: "",
    age: null,
  });

  // Load initial data from localStorage once on mount
  useEffect(() => {
    const savedData = localStorage.getItem("signupFormData");
    if (savedData) {
      try {
        const parsedData = JSON.parse(savedData);
        setFormData(parsedData);
      } catch (error) {
        console.error("Failed to parse saved form data:", error);
      }
    }
  }, []); // Empty dependency array ensures this runs only once

  // Update specific field in formData and save the entire formData to localStorage
  const updateField = (field, value) => {
    setFormData((prev) => {
      // Retrieve existing form data from localStorage
      const existingData = JSON.parse(localStorage.getItem("signupFormData"));
      if (existingData) {
        // console.log("existingData", existingData);

        // Merge existing data with the new update
        const updatedFormData = {
          ...existingData,
          [field]: field === "age" ? parseInt(value) : value,
        };

        // console.log("updatedFormData", updatedFormData);
        // Save the merged data back to localStorage
        localStorage.setItem("signupFormData", JSON.stringify(updatedFormData));

        // Update state with the merged data
        return updatedFormData;
      } else {
        return { ...prev, [field]: value };
      }
    });
  };

  // Clear the form data dynamically by resetting each field to an empty string or null
  const clearFormData = () => {
    setFormData((prev) => {
      // Create a new object with the same keys, but all values cleared
      const clearedData = Object.keys(prev).reduce((acc, key) => {
        acc[key] = typeof prev[key] === "number" ? null : ""; // Set numbers to null, everything else to empty string
        return acc;
      }, {});

      // Clear localStorage and reset state
      localStorage.removeItem("signupFormData");
      return clearedData;
    });
  };

  return (
    <FormContext.Provider value={{ formData, updateField, clearFormData }}>
      {children}
    </FormContext.Provider>
  );
};
