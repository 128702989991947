import React from "react";
import { useStudentSignUp } from "../../hooks";
import { FirstForm, SecondForm } from "./components";
import Saly from "../../assets/Saly.png";
import Thank from "../../assets/ThankyouSignup.png";
import useWindowWidth from "../../hooks/useWindowWidth";
import "./StudentSignUp.scss";
import { ThankYouSignUp } from "../../modals";
import { useNavigate } from "react-router-dom";

const StudentSignUp = () => {
  const {
    isSuccessVisible,
    changeSuccessVisibility,
    page,
    setPage,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    isSaving,
    email,
    setEmail,
    phone,
    setPhone,
    age,
    setAge,
    showError,
    setShowError,
    error,
    firstError,
    lastError,
    password,
    setPassword,
    setAddress,
    setZipCode,
    thinkingCareer,
    setThinkingCareer,
    handleSignUp,
    flavor,
    setFlavor,
    careers,
    setCareers,
    grade,
    setGrade,
    termsCheck,
    setTermsCheck,
    shareDataCheck,
    setShareDataCheck,
    getErrorFromBackend,
    closeErrorModal,
    isErroModalOpened,
    confirmPassword,
    setConfirmPassword,
    passwordError,
    setPasswordError,
    certaintyError,
    setCertaintyError,
    affiliateCode,
    setAffiliateCode,
  } = useStudentSignUp();
  const navigate = useNavigate();

  const { width, isMobile } = useWindowWidth();

  return (
    <section className="StudentSignUp">
      <h3>Signing up as Career Explorer</h3>
      {page === 1 ? (
        <FirstForm
          setPage={setPage}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          phone={phone}
          setShowError={setShowError}
          showError={showError}
          error={error}
          firstError={firstError}
          lastError={lastError}
          setPhone={setPhone}
          age={age}
          setage={setAge}
          setCompleteAddress={setAddress}
          setZipCode={setZipCode}
        />
      ) : (
        <SecondForm
          password={password}
          setPassword={setPassword}
          thinkingCareer={thinkingCareer}
          setThinkingCareer={setThinkingCareer}
          goBack={setPage}
          handleSignUp={handleSignUp}
          flavor={flavor}
          setFlavor={setFlavor}
          careers={careers}
          setCareers={setCareers}
          grade={grade}
          affiliateCode={affiliateCode}
          setAffiliateCode={setAffiliateCode}
          isSaving={isSaving}
          setGrade={setGrade}
          termsCheck={termsCheck}
          setTermsCheck={setTermsCheck}
          shareDataCheck={shareDataCheck}
          setShareDataCheck={setShareDataCheck}
          isErroModalOpened={isErroModalOpened}
          closeErrorModal={closeErrorModal}
          getErrorFromBackend={getErrorFromBackend}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          passwordError={passwordError}
          setPasswordError={setPasswordError}
          certaintyError={certaintyError}
          setCertaintyError={setCertaintyError}
        />
      )}

      {/* {!isMobile && <div style={{paddingLeft: '30px', alignSelf: 'flex-start'}}>
          <img src={Saly} alt="" />
      </div>} */}

      {/* {!isMobile && <div style={{display: 'flex', width: '100%', position: 'absolute', height: '100%', flexDirection: 'column', justifyContent: 'flex-end'}}>
        <div style={{ paddingLeft: '30px', alignSelf: 'flex-start' }}>
          <img src={Saly} alt="" />
        </div>  
      </div>} */}

      {width > 1221 && (
        <img
          alt=""
          src={Saly}
          style={{ position: "absolute", left: "30px", bottom: 0 }}
        />
      )}

      <ThankYouSignUp
        isOpened={isSuccessVisible}
        onClose={() => {
          changeSuccessVisibility(false);
          navigate("/");
        }}
        image={Thank}
        title={"Success!"}
        EmailCheckText={"Please check your inbox to verify your email"}
      />
    </section>
  );
};

export default StudentSignUp;
