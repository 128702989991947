// components/Checkbox.jsx
import React from "react";
import "./Checkbox.scss";

const CheckBox = ({ id, checked, changeHandler }) => {
  return (
    <div className="checkbox-container">
      <label htmlFor={id} className="checkbox-label">
        <input
          type="checkbox"
          checked={checked}
          onChange={changeHandler}
          id={id}
          className="checkbox-input"
        />
        <span
          className={`checkmark ${
            !checked && id !== "allow_data_sharing" ? "checkmark--error" : ""
          }`}
        ></span>
      </label>
    </div>
  );
};

export default CheckBox;
