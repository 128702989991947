import React, { useState } from "react";
import CheckBox from "../../../../components/Checkbox";
import classnames from "classnames";
import SuccessImage from "../../../../assets/success.png";
import passEyeClose from "../../../../assets/passEyeClose.svg";
import passEyeOpen from "../../../../assets/passEyeOpen.svg";
import { ThankYouSignUp } from "../../../../modals";
import "./SecondForm.scss";
import useSignUpStudentSecond from "../../../../hooks/useSignUpStudentSecond";
import { Link } from "react-router-dom";

const SecondForm = ({
  goBack,
  password,
  setPassword,
  handleSignUp,
  isSaving,
  flavor,
  setFlavor,
  careers,
  setCareers,
  grade,
  setGrade,
  affiliateCode,
  termsCheck,
  setTermsCheck,
  shareDataCheck,
  setShareDataCheck,
  getErrorFromBackend,
  closeErrorModal,
  isErroModalOpened,
  confirmPassword,
  setConfirmPassword,
  passwordError,
  setPasswordError,
  certaintyError,
  setCertaintyError,
  setAffiliateCode,
}) => {
  const {
    showPassword,
    setShowConfirmPassword,
    showConfirmPassword,
    setShowPassword,
  } = useSignUpStudentSecond();

  return (
    <div className="SecondForm">
      <div className="SecondForm-cont">
        <div className="SecondForm-cont__1">
          <div className="input">
            <p>Password*</p>
            <div className="passwordInput">
              <input
                value={password}
                onChange={(e) => {
                  setPasswordError("");
                  setPassword(e.target.value);
                }}
                type={showPassword ? "text" : "password"}
                className="input-password"
              />
              {showPassword ? (
                <img
                  className="input-passimg"
                  onClick={() => setShowPassword((prev) => !prev)}
                  src={passEyeOpen}
                  alt=""
                />
              ) : (
                <img
                  className="input-passimg"
                  onClick={() => setShowPassword((prev) => !prev)}
                  src={passEyeClose}
                  alt=""
                />
              )}
            </div>
            {passwordError && (
              <div className="FirstForm__error">
                <img src={Error} alt="" />
                <p>{passwordError}</p>
              </div>
            )}
          </div>
        </div>
        <div className="SecondForm-cont__2">
          <div className="input">
            <p>Confirm Password*</p>

            <div className="passwordInput">
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="input-password"
                value={confirmPassword}
                onChange={(e) => {
                  setPasswordError("");
                  setConfirmPassword(e.target.value);
                }}
              />
              {showConfirmPassword ? (
                <img
                  className="input-confpassimg"
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                  src={passEyeOpen}
                  alt=""
                />
              ) : (
                <img
                  className="input-confpassimg"
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                  src={passEyeClose}
                  alt=""
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="SecondForm__tag">
        <p>Favorite Ice Cream Flavor</p>
        <input value={flavor} onChange={(e) => setFlavor(e.target.value)} />
      </div>
      <div className="SecondForm__tag">
        <p>What career(s) are you thinking about? (comma-separated)</p>
        <input value={careers} onChange={(e) => setCareers(e.target.value)} />
      </div>
      <div className="SecondForm__level">
        <p>Level of certainty of your future career path</p>
        <div className="SecondForm__level__cont">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
            <div
              onClick={() => {
                setCertaintyError("");
                setGrade(item);
              }}
              key={item}
              className="SecondForm__level__cont__grades"
            >
              <div
                className={classnames({
                  activeCircle: grade === item,
                })}
              />
              <p>{item}</p>
            </div>
          ))}
        </div>
        {certaintyError && (
          <div className="FirstForm__error">
            <img src={Error} alt="" />
            <p>{certaintyError}</p>
          </div>
        )}
      </div>
      <div className="SecondForm__tag">
        <p>Affiliate Code</p>
        <input
          value={affiliateCode}
          onChange={(e) => setAffiliateCode(e.target.value)}
        />
      </div>
      <div className="SecondForm__terms">
        <CheckBox
          value={termsCheck}
          changeHandler={() => setTermsCheck((prev) => !prev)}
          id={"checkbox1"}
        />
        <p>
          I certify that I am over the age of 14 and that I have read and agree
          to the {" "}
          <Link target="_blank" to="/privacypolicy">
            Privacy Policy
          </Link>{" "}
          and the website’s{" "}
          <Link target="_blank" to="/terms">
            Terms of Service.
          </Link>
          *
        </p>
      </div>
      <div className="SecondForm__terms">
        <CheckBox
          value={shareDataCheck}
          changeHandler={() => setShareDataCheck((prev) => !prev)}
          id={"checkbox2"}
        />
        <p>
          I allow Career Scoops Inc. to share my data with third-party
          educational institutions as defined in the{" "}
          <Link target="_blank" to="/privacypolicy">
            Privacy Policy
          </Link>
          .
        </p>
      </div>
      <div className="SecondForm-navigation">
        <button
          onClick={() => goBack(1)}
          className="SecondForm-navigation__back"
        >
          Back
        </button>
        <button
          onClick={() =>
            termsCheck &&
            !isSaving &&
            !passwordError &&
            !certaintyError &&
            handleSignUp()
          }
          disabled={!termsCheck || isSaving}
          className={classnames("SecondForm-navigation__continue", {
            // disable: !(termsCheck && shareDataCheck),
            disable: !termsCheck || isSaving,
          })}
        >
          Sign Up
        </button>
      </div>
      <ThankYouSignUp
        image={SuccessImage}
        isOpened={isErroModalOpened}
        title={"Sign up Failed."}
        EmailCheckText={getErrorFromBackend()}
        onClose={closeErrorModal}
      />
    </div>
  );
};

export default SecondForm;
