import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useResults from "./useResults";

const useCareer = () => {
  const navigate = useNavigate();
  const { profile } = useSelector((state) => state.profileReducer);
  const {
    chosenItem,
    handlescore,
    handleBook,
    handleBack,
    isSuccessModalOpened,
    closeSuccessModal,
  } = useResults();

  const { state } = useLocation();
  function handleBookValidation(id) {
    if (profile.pricing_plan || profile.affiliate_code) {
      handleBook(id);
    } else {
      navigate("/pricingplan");
    }
  }

  const navigateBack = () => {
    navigate(-1);
  };
  return {
    navigateBack,
    state,
    chosenItem,
    isSuccessModalOpened,
    closeSuccessModal,
    handlescore,
    handleBack,
    handleBook,
    handleBookValidation,
  };
};

export default useCareer;
