import React from "react";
import SalyLeft from "../../assets/SalyLeft.png";
import useForgetPassword from "../../hooks/useForgetPassword";
import Error from "../../assets/error.svg";
import ClosedEye from "../../assets/passEyeClose.svg";
import OpenEye from "../../assets/passEyeOpen.svg";
import SuccessImage from "../../assets/success.png";
import { Success, ThankYouSignUp } from "../../modals";
import "./ForgotPassword.scss";

const ForgotPassword = () => {
  const {
    showError,
    email,
    handleEmail,
    handleBack,
    emailError,
    sendEmail,
    step,
    backFromResetPassword,
    handlePasswordChange,
    handleConfirmPasswordChange,
    handlePasswordEye,
    handleConfirPasswordEye,
    passwordObj,
    confirmPasswordObj,
    ResetPassword,
    confirmationError,
    isErrorVisible,
    isSuccessModalOpened,
    closeSuccessModal,
    isErrorModalOpened,
    closeErrorModal,
    errorFromBackend,
  } = useForgetPassword();
  return (
    <section className="ForgotPassword">
      {step === 1 ? (
        <div className="ForgotPassword-cont">
          <p className="ForgotPassword-cont__title">Forgot Password</p>
          <div className="ForgotPassword-cont__input">
            <div className="input">
              <p>Email</p>
              <input
                type="email"
                value={email}
                onChange={handleEmail}
                placeholder="Email"
              />
              {showError ? (
                <div className="errorCont">
                  <img src={Error} alt="Error" />
                  <p className="errorCont__text">{emailError}</p>
                </div>
              ) : null}
            </div>
          </div>
          <div className="ForgotPassword-cont__navigation">
            <button
              onClick={handleBack}
              className="ForgotPassword-cont__navigation__back"
            >
              <p className="ForgotPassword-cont__navigation__back__backText">
                Back
              </p>
            </button>
            <button
              className="ForgotPassword-cont__navigation__email"
              onClick={() => {
                sendEmail(email);
              }}
            >
              <p className="ForgotPassword-cont__navigation__email__emailText">
                Send to Email
              </p>
            </button>
          </div>
          <Success
            image={SuccessImage}
            isOpened={isSuccessModalOpened}
            title={"success"}
            text="Check your Email"
            close={closeSuccessModal}
          />
          <ThankYouSignUp
            image={SuccessImage}
            isOpened={isErrorModalOpened}
            title={"Reset Password Failed."}
            EmailCheckText={errorFromBackend}
            onClose={closeErrorModal}
          />
        </div>
      ) : (
        <div className="ForgotPassword__PasswordPage">
          <p className="ForgotPassword__PasswordPage__title">Reset Password </p>
          <div className="ForgotPassword__PasswordPage__cont">
            <div className="ForgotPassword__PasswordPage__cont__password">
              <p className="ForgotPassword__PasswordPage__cont__password__text">
                New password
              </p>
              <div className="ForgotPassword__PasswordPage__cont__password__inpCont">
                <input
                  type="text"
                  className="ForgotPassword__PasswordPage__cont__password__inpCont__inp"
                  value={
                    passwordObj.isEyeClosed
                      ? passwordObj.maskedPassword
                      : passwordObj.password
                  }
                  onChange={handlePasswordChange}
                />
                <img
                  src={passwordObj.isEyeClosed ? ClosedEye : OpenEye}
                  alt="ClosedEye"
                  className="ForgotPassword__PasswordPage__cont__password__inpCont__img"
                  onClick={handlePasswordEye}
                />
              </div>
            </div>
            <div className="ForgotPassword__PasswordPage__cont__password">
              <p className="ForgotPassword__PasswordPage__cont__password__text">
                Confirm password
              </p>
              <div className="ForgotPassword__PasswordPage__cont__password__inpCont">
                <input
                  type="text"
                  className="ForgotPassword__PasswordPage__cont__password__inpCont__inp"
                  value={
                    confirmPasswordObj.isEyeClosed
                      ? confirmPasswordObj.maskedPassword
                      : confirmPasswordObj.password
                  }
                  onChange={handleConfirmPasswordChange}
                />
                <img
                  src={confirmPasswordObj.isEyeClosed ? ClosedEye : OpenEye}
                  alt="ClosedEye"
                  className="ForgotPassword__PasswordPage__cont__password__inpCont__img"
                  onClick={handleConfirPasswordEye}
                />
              </div>
            </div>
            {isErrorVisible ? (
              <div className="errorCont">
                <img src={Error} alt="Error" />
                <p className="errorCont__text">{confirmationError}</p>
              </div>
            ) : null}
          </div>
          <div className="ForgotPassword-cont__navigation">
            <button
              onClick={backFromResetPassword}
              className="ForgotPassword-cont__navigation__back"
            >
              <p className="ForgotPassword-cont__navigation__back__backText">
                Back
              </p>
            </button>
            <button
              className="ForgotPassword-cont__navigation__email"
              onClick={ResetPassword}
            >
              <p className="ForgotPassword-cont__navigation__email__emailText">
                Reset password
              </p>
            </button>
          </div>
        </div>
      )}
      <img src={SalyLeft} alt="SalyLeft" />
    </section>
  );
};

export default ForgotPassword;
