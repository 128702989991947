// components/forms/Step1.jsx
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "../../../../contexts/FormContext"; // Ensure correct import path
import useFormValidation from "../../../../hooks/new/useFormValidation"; // Ensure correct import path
import {
  InputField,
  SelectField,
} from "../../../../components/forms/InputField/InputField"; // Ensure correct import path
import "./SignUp.scss";

const Step1 = () => {
  const { formData, updateField } = useFormContext(); // Access form data and update function
  const { errors, validateField, clearErrors } = useFormValidation();
  const navigate = useNavigate();

  const ageOptions = [...new Array(100)]
    .filter((_, index) => index > 13)
    .map((_, index) => ({
      label: index + 14, // Displayed in the dropdown
      value: index + 14, // Value that is submitted
    }));

  // Function to validate all fields and determine if there are errors
  const validateFields = () => {
    const fieldsToValidate = [
      "first_name",
      "last_name",
      "email",
      "phone",
      "address",
      "city",
      "state",
      "zip_code",
      "age",
    ];

    // Use a flag to track if there are any errors
    let hasErrors = false;

    // Validate each field and accumulate errors
    fieldsToValidate.forEach((field) => {
      const fieldError = validateField(field, formData[field]);
      if (fieldError) {
        hasErrors = true; // Mark that there are errors
      }
    });

    // Return whether there are any errors
    return hasErrors;
  };

  // Separate handler for the "Continue" button click
  const handleContinue = useCallback(() => {
    console.log("Form data before validation:", formData);

    // Validate fields and check for errors
    const hasErrors = validateFields();
    console.log("hasErrors", hasErrors);

    if (!hasErrors) {
      clearErrors(); // Clear errors before proceeding
      saveToLocalStorage(); // Save data before navigating
      navigate("/new/student/2"); // Navigate to Step 2
    } else {
      console.error("Validation errors found:", errors);
    }
  }, [formData, clearErrors, navigate, validateFields]);

  // Save form data to localStorage
  const saveToLocalStorage = () => {
    localStorage.setItem("signupFormData", JSON.stringify(formData));
  };

  // Handler to clear the form and localStorage
  const handleClearForm = () => {
    const initialFormData = {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      zip_code: "",
      age: "",
      // Include any other fields you have
    };

    // Reset formData in the context
    Object.keys(initialFormData).forEach((field) =>
      updateField(field, initialFormData[field])
    );

    // Clear localStorage
    localStorage.removeItem("signupFormData");
  };

  return (
    <div className="signup">
      <div className="signup-container">
        <div className="signup-wrapper">
          <InputField
            label="First Name"
            field="first_name"
            value={formData["first_name"] || ""}
            onChange={(value) => updateField("first_name", value)}
            error={errors["first_name"]}
          />
          <InputField
            label="Last Name"
            field="last_name"
            value={formData["last_name"] || ""}
            onChange={(value) => updateField("last_name", value)}
            error={errors["last_name"]}
          />
        </div>
        <div className="signup-wrapper">
          <InputField
            label="Email"
            field="email"
            type="email"
            value={formData.email || ""}
            onChange={(value) => updateField("email", value)}
            error={errors.email}
          />
        </div>

        <div className="signup-wrapper">
          <InputField
            label="Phone"
            field="phone"
            type="tel"
            value={formData.phone || ""}
            onChange={(value) => updateField("phone", value)}
            error={errors.phone}
          />
        </div>
        <div className="signup-wrapper">
          <InputField
            label="Address"
            field="address"
            value={formData.address || ""}
            onChange={(value) => updateField("address", value)}
            error={errors.address}
          />
        </div>
        <div className="signup-wrapper">
          <InputField
            label="City"
            field="city"
            value={formData.city || ""}
            onChange={(value) => updateField("city", value)}
            error={errors.city}
          />
          <InputField
            label="State"
            field="state"
            value={formData.state || ""}
            onChange={(value) => updateField("state", value)}
            error={errors.state}
          />
        </div>
        <div className="signup-wrapper">
          <SelectField
            label="Age"
            field="age"
            value={formData.age || ""}
            options={ageOptions}
            onChange={(value) => updateField("age", value)}
            error={errors.age}
          />
          <InputField
            label="Zip Code"
            field="zip_code"
            value={formData["zip_code"] || ""}
            onChange={(value) => updateField("zip_code", value)}
            error={errors["zip_code"]}
          />
        </div>
      </div>

      <div className="signup-navigation-wrapper">
        <button onClick={handleClearForm} className="signup-navigation__clear">
          Clear Form
        </button>
        <button
          onClick={handleContinue}
          className="signup-navigation__continue"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Step1;
