import { useState } from "react";
import axiosClient from "../common/api/request";
import { useDispatch } from "react-redux";
import { initGetSessions } from "../redux/actions";

const useCustomSession = (active) => {
  const dispatch = useDispatch();
  const [topic, setTopic] = useState("");
  const [isSuccessModalOpened, setIsSuccessModalOpened] = useState(false);
  const [isErrorModalOpened, setIsErrorModalOpened] = useState(false);
  const sendRequest = () => {
    topic &&
      axiosClient
        .post("mentor_sessions/", {
          topic,
          datetime: new Date(),
        })
        .then(() => {
          openSuccessModal();
          dispatch(initGetSessions());
          setTopic("");
        })
        .catch((e) => {
          if (e?.response?.status === 403) {
            setIsErrorModalOpened(e?.response?.data?.detail);
          }
          closeSuccessModal();
        });
  };
  const openSuccessModal = () => {
    setIsSuccessModalOpened(true);
  };
  const closeSuccessModal = () => {
    setIsSuccessModalOpened(false);
  };
  return {
    closeSuccessModal,
    isSuccessModalOpened,
    isErrorModalOpened,
    openSuccessModal,
    sendRequest,
    setTopic,
    topic,
  };
};

export default useCustomSession;
