import React from "react";
import arrowLeft from "../../../../assets/grayArrowLeft.svg";
import "./Career.scss";
import classNames from "classnames";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import useCareer from "../../../../hooks/useCareer";
import { BookModal, Success } from "../../../../modals";
import SuccessImage from "../../../../assets/success.png";

const Career = () => {
  const {
    navigateBack,
    state,
    isSuccessModalOpened,
    closeSuccessModal,
    chosenItem,
    handleBack,
    handlescore,
    handleBookValidation,
  } = useCareer();
  const { isMobile } = useWindowWidth();
  return (
    <div className="CareerCont">
      <div className="infoCont">
        <div className="CareerCont__header">
          <div
            onClick={() => {
              navigateBack();
            }}
            className={classNames("CareerCont__header__iconWrapper", {
              hide: isMobile,
            })}
          >
            <img
              src={arrowLeft}
              alt=""
              className="CareerCont__header__iconWrapper__icon"
            />
          </div>
          <div className="CareerCont__header__textCont">
            <p
              className={classNames("CareerCont__header__textCont__Profecy", {
                hide: isMobile,
              })}
            >
              {state.text}
            </p>
            <p className="CareerCont__header__textCont__mentor">
              <span className="CareerCont__header__textCont__mentor__key">
                Mentor:{" "}
              </span>
              {state.first_name}
            </p>
          </div>
        </div>
        <p className="CareerCont__decription">{`${state.description_long}`}</p>
      </div>

      <BookModal
        handlescore={handlescore}
        chosenItem={chosenItem}
        handleBook={() => handleBookValidation(state.id)}
        text={state?.description}
        isContentType={true}
        handleBack={() => handleBack(state.id)}
      />
      <Success
        image={SuccessImage}
        title="Success!"
        text="Thank you for letting us know you want to learn more! We'll take it from here based on your Career Scoops plan."
        isOpened={isSuccessModalOpened}
        close={closeSuccessModal}
      />
    </div>
  );
};

export default Career;
