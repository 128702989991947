import React from "react";
import { Link } from "react-router-dom";
import SalyLeft from "../../assets/SalyLeft.png";
import { useSignIn } from "../../hooks";
import errorimg from "../../assets/error.svg";
import useWindowWidth from "../../hooks/useWindowWidth";
import SuccessImage from "../../assets/success.png";
import { ThankYouSignUp } from "../../modals";

import "./SignIn.scss";

const SignIn = () => {
  const {
    email,
    setEmail,
    password,
    setPassword,
    handleLogin,
    isLoading,
    showEmailError,
    emailError,
    passwordError,
    showPasswordError,
    isErroModalOpened,
    closeErrorModal,
    getErrorFromBackend,
  } = useSignIn();

  const { width, isMobile } = useWindowWidth();

  return (
    <section className="SignIn">
      {!isLoading ? (
        <div className="SignIn-cont">
          <p className="SignIn-cont__title">Log in</p>
          <div className="SignIn-cont__input">
            <div className="input">
              <p>Email</p>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
              />
              {showEmailError ? (
                <div className="Signin-errorCont">
                  <img src={errorimg} alt="errorimg" />
                  <p className="Signin-errorCont__text">{emailError}</p>
                </div>
              ) : null}
            </div>
            <div className="input">
              <p>Password</p>
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
              />
              {showPasswordError ? (
                <div className="Signin-errorCont">
                  <img src={errorimg} alt="errorimg" />
                  <p className="Signin-errorCont__text">{passwordError}</p>
                </div>
              ) : null}
            </div>
          </div>
          <div className="SignIn-cont__link">
            <Link to={"/forgotpassword"}>Forgot Password</Link>
          </div>

          <button
            onClick={() => {
              handleLogin(email, password);
            }}
          >
            Login
          </button>

          <div className="SignIn-cont__signup">
            Don't have an account yet? <Link to={"/signup"}>Sign Up</Link>
          </div>
          <ThankYouSignUp
            image={SuccessImage}
            isOpened={isErroModalOpened}
            title={"Login Failed."}
            EmailCheckText={getErrorFromBackend()}
            onClose={closeErrorModal}
          />
        </div>
      ) : (
        <div>Loading...</div>
      )}
      {!isMobile && <img src={SalyLeft} alt="" />}
    </section>
  );
};

export default SignIn;
