import React from "react";
import Step1 from "./components/SignUp/Step1";
import Step2 from "./components/SignUp/Step2";

const NewStudentSignUp = ({ step }) => {
  return (
    <section className="StudentSignUp">
      <h3>Signing up as Career Explorer</h3>
      {step === 1 ? <Step1 /> : <Step2 />}
    </section>
  );
};

export default NewStudentSignUp;
