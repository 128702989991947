import React, { useState } from "react";
import passEyeClose from "../../../assets/passEyeClose.svg"; // Ensure the correct import path
import passEyeOpen from "../../../assets/passEyeOpen.svg"; // Ensure the correct import path
import "./InputField.scss";

const InputField = ({
  label,
  field,
  type = "text",
  value = "",
  onChange,
  error,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  // Determine the input type based on the provided type prop and visibility state
  const inputType = type === "password" && isPasswordVisible ? "text" : type;

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  return (
    <div className="input-field">
      <label htmlFor={field}>{label}</label>
      <div className="input-wrapper">
        <input
          id={field}
          type={inputType}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
        {type === "password" && (
          <img
            src={isPasswordVisible ? passEyeOpen : passEyeClose}
            alt="Toggle password visibility"
            className="password-toggle-icon"
            onClick={togglePasswordVisibility}
          />
        )}
      </div>
      {error && (
        <div className="input__error">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

const SelectField = ({
  label,
  field,
  value = "",
  options = [],
  onChange,
  error,
}) => {
  return (
    <div className="input-field">
      <label htmlFor={field}>{label}</label>
      <select
        id={field}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="select-field"
      >
        <option value="">Select an option</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && (
        <div className="input__error">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export { InputField, SelectField };
